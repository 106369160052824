import { useState, useEffect } from 'react';

const defaultSettings = {
  enableHighAccuracy: true, // Enable high accuracy for better initial position
  timeout: 5000, // Set reasonable timeout of 5 seconds
  maximumAge: 60000, // Allow cached positions up to 30 seconds old
};

export const usePosition = (watch = false, userSettings = {}) => {
  const settings = {
    ...defaultSettings,
    ...userSettings,
  };

    // iOS doesn't have navigator.permissions.query, so we skip it if we failed to get the user's location
  // const requestLocation = async () => {
  //   if (!/(safari)/i.test(navigator.userAgent)) {
  //     const permission = await navigator.permissions.query({ name: 'geolocation' });
  //   }
  // }

  const [position, setPosition] = useState({});
  const [error, setError] = useState(null);

  const onChange = ({ coords, timestamp }) => {
    setPosition({
      latitude: coords.latitude,
      longitude: coords.longitude,
      accuracy: coords.accuracy,
      speed: coords.speed,
      heading: coords.heading,
      timestamp,
    });
  };

  const onError = (error) => {
    console.log('LOCATION ERROR', error);
    setError(error);
  };

  useEffect(() => {
    if (!navigator || !navigator.geolocation) {
      setError('Geolocation is not supported');
      return;
    }

    // First try to get a quick cached position
    navigator.geolocation.getCurrentPosition(
      onChange,
      () => {}, // Ignore errors on first try
      { ...settings, maximumAge: Infinity, timeout: 100 }
    );

    // Then get an accurate position
    if (watch) {
      const watcher = navigator.geolocation.watchPosition(onChange, onError, settings);
      return () => navigator.geolocation.clearWatch(watcher);
    }

    navigator.geolocation.getCurrentPosition(onChange, onError, settings);
  }, [settings.enableHighAccuracy, settings.timeout, settings.maximumAge, watch]);

  return { ...position, positionError: error };
};
